var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},[_vm._l((_vm.Menu),function(menuItem){return [(menuItem.heading && !menuItem.sub && menuItem.permission)?_c('li',{key:menuItem.index,staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v(_vm._s(menuItem.name))])]):_vm._e(),(menuItem.sub && menuItem.permission)?_c('li',{key:menuItem.index,staticClass:"menu-item menu-item-submenu",class:{
        'menu-item-open': _vm.hasActiveChildren(menuItem.sub),
      },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon",class:menuItem.icon}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(menuItem.name))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(menuItem.name))])])]),_vm._l((menuItem.sub),function(subMenuItem){return [(subMenuItem.permission)?_c('router-link',{key:subMenuItem.index,attrs:{"custom":"","to":subMenuItem.to || '#'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[(!!subMenuItem.icon)?_c('i',{staticClass:"menu-icon",class:subMenuItem.icon}):_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(subMenuItem.name))])])])]}}],null,true)}):_vm._e()]})],2)])]):_vm._e(),(!menuItem.sub && menuItem.permission)?_c('router-link',{key:menuItem.index,class:{ 'd-none': menuItem.subActivePaths },attrs:{"custom":"","to":menuItem.to || '#'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon",class:menuItem.icon}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(menuItem.name))])])])]}}],null,true)}):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }