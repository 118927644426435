<template>
  <ul class="menu-nav">
    <template v-for="menuItem in Menu">
      <!--      <pre class="text-white" :key="menuItem.index">{{ menuItem.name }}</pre>-->

      <li
        :key="menuItem.index"
        v-if="menuItem.heading && !menuItem.sub && menuItem.permission"
        class="menu-section"
      >
        <h4 class="menu-text">{{ menuItem.name }}</h4>
      </li>

      <li
        v-if="menuItem.sub && menuItem.permission"
        :key="menuItem.index"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{
          'menu-item-open': hasActiveChildren(menuItem.sub),
        }"
      >
        <a href="#" class="menu-link menu-toggle">
          <i class="menu-icon" :class="menuItem.icon"></i>
          <span class="menu-text">{{ menuItem.name }}</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <li aria-haspopup="true" class="menu-item menu-item-parent">
              <span class="menu-link">
                <span class="menu-text">{{ menuItem.name }}</span>
              </span>
            </li>

            <template v-for="subMenuItem in menuItem.sub">
              <router-link
                custom
                v-if="subMenuItem.permission"
                :key="subMenuItem.index"
                :to="subMenuItem.to || '#'"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i
                      v-if="!!subMenuItem.icon"
                      class="menu-icon"
                      :class="subMenuItem.icon"
                    ></i>
                    <i v-else class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>

                    <span class="menu-text">{{ subMenuItem.name }}</span>
                  </a>
                </li>
              </router-link>
            </template>
          </ul>
        </div>
      </li>

      <router-link
        custom
        v-if="!menuItem.sub && menuItem.permission"
        :key="menuItem.index"
        :to="menuItem.to || '#'"
        :class="{ 'd-none': menuItem.subActivePaths }"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon" :class="menuItem.icon"></i>
            <span class="menu-text">{{ menuItem.name }}</span>
          </a>
        </li>
      </router-link>
    </template>
  </ul>
</template>

<script>
import { SET_MENU } from "@/core/services/store/modules/ui.module";
export default {
  name: "KTMenu",
  data() {
    return {
      hasUpdate: true,
    };
  },
  computed: {
    Menu() {
      const list = this.$store.getters.getMenu;
      if (Array.isArray(list) && list.length) {
        return list;
      } else {
        return [];
      }
    },
  },
  methods: {
    hasActiveChildren(match) {
      let path = this.$route["path"];
      let hasActiveChild = match.some((item) => {
        return item.to === path;
      });
      return hasActiveChild;
    },
  },
  mounted() {
    this.$store.dispatch(SET_MENU);
  },
};
</script>
